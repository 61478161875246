@import "../../import.scss";

.Label {
  margin-bottom: 8px;
  font-size: 80%;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  &.center {
    text-align: center;
    margin-bottom: 0;
  }
  &.big {
    font-size: 120%;
  }
}
