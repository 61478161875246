@import "../../import.scss";

.SingleImageUploader {
  .removeImageButton {
    font-size: 80%;
    font-weight: bold;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#555;
    transition: color 0.3s;
    &:hover {
      color: $blue;
    }
  }
  .ulWrap {
    height: 200px;
    display: flex;
    cursor: pointer;
  }
}
