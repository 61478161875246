$lightShadow: 0px 0px 2px 0px #00000044;

$darkGrey: #28343d;
$lightGrey: #32414d;

$background: #f2f2f2;

$green: #89bd21;
$blue: #2997ff;
$yellow: #cec600;
$red: #ee7157;

$darkGreen: #5d8720;
$darkBlue: #007078;
$darkYellow: #a99a00;
$darkRed: #da594c;

$darkerGreen: #486c17;
$darkerBlue: #005055;
$darkerYellow: #837800;
$darkerRed: #890c00;

$orange: #f2c011;

$darkText: #2a343c;

$white: #f2f2f2;
$black: #222222;
