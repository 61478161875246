@import "../../import.scss";

.PlaceExhibitScreen {
  display: flex;

  .sidebar {
    background-color: #f2f2f2;
    box-shadow: $lightShadow;
    border-radius: 6px;
    margin: 12px;
    position: absolute;

    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    max-width: 250px;
    padding: 12px;
    overflow: hidden;
    transition: max-width 1s;
  }
  .styleOverlay {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px;
    z-index: 100;
    width: 150px;
    background-color: #eee;

    box-shadow: $lightShadow;
    padding: 12px;
    border-radius: 6px;
    .sliderHolder {
      display: flex;
      align-items: center;
      gap: 8px;
      .sizeInput {
        all: unset;
        width: 40px;
        text-align: center;
        border-bottom: 1px solid #555;
        font-size: 80%;
        font-weight: bold;
      }
    }
    .closeOverlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background-color: #eee;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #ddd;
      }
    }
  }
  .iconAction {
    display: flex;
    align-items: center;
    transition: color 0.3s, background-color 0.3s;
    height: 35px;
    border-radius: 6px;

    color: #555;
    cursor: pointer;
    &:hover {
      color: $white;
      background-color: $blue;
    }
    .icon {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .poiSelector {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 20px;
    margin-top: 12px;
    .poiItem {
      img {
        width: 60px;
        height: 60px;
      }
      .poiName {
        text-align: center;
        font-size: 80%;
        font-weight: bold;
      }
    }
  }
}
