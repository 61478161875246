@import "../../import.scss";

.StyledTextarea {
  display: flex;
  flex-direction: column;
  .styledTextarea {
    min-height: 80px;
    outline: none;
    border: none;

    padding: 12px;
    font-size: 100%;

    border-radius: 6px;
    box-shadow: $lightShadow;
    border: 1px solid $white;
    &:focus {
      border: 1px solid $orange;
    }
  }
}
