@import "../../import.scss";

.FullMap {
  display: flex;
  flex: 1;
  position: relative;
}
.dotPopup {
  width: 200px;
  height: 200px;
  background-color: $white;
  border-radius: 6px;
  z-index: 10;
  box-shadow: $lightShadow;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  .popupImage {
    flex: 1;
    object-fit: cover;
  }
  .popupName {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: #00000077;
    font-weight: bold;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
