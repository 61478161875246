@import "../../import.scss";

.TourList {
  .tourResult {
    padding: 12px;
    display: flex;
    align-items: center;
    background-color: $white;
    margin-bottom: 8px;
    box-shadow: $lightShadow;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    &.noTreasure + .treasureHunt {
      border-top: 2px solid $blue;
    }
    .tourName {
      flex: 1;
    }
    .img {
      width: 50px;
      height: 50px;
      display: flex;
      margin-right: 12px;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
    &:hover {
      background-color: #ddd;
    }
  }
  .deleteTourButton {
    background-color: red;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #aaa;
    &:hover {
      color: $white;
      background-color: $red;
    }
  }
}
