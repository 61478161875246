@import "../../import.scss";

.TextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s, color 0.3s;
  padding-left: 12px;
  padding-right: 12px;
  .icon {
    transition: color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: $orange;
    color: $white;
    .icon {
      color: $white;
    }
  }
}
