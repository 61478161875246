@import "../../import.scss";

.QrManager {
  .flex {
    display: flex;
    gap: 12px;
    .right {
      background-color: $white;
      flex: 1;

      border-radius: 6px;
    }
  }
  .cornerStyles {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
    .cornerStyle {
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        box-shadow: $lightShadow;
      }
      &.selected {
        background-color: lightgreen;
      }
    }
    img {
      height: 100px;
      width: 100px;
    }
  }
  .dotStyles {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
    .dotStyle {
      cursor: pointer;
      transition: background-color 0.3s;
      border-radius: 6px;
      &:hover {
        box-shadow: $lightShadow;
      }
      &.selected {
        background-color: lightgreen;
      }
    }
    img {
      height: 100px;
      width: 100px;
    }
  }
}
