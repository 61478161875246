@import "../../import.scss";

.ShortLinkMaker {
  .left,
  .right {
    flex: 1;
    display: flex;
  }
  .left {
    display: flex;
    margin-top: 20px;
    .StyledButton {
      margin-top: 20px;
    }
    .StyledInput {
      flex: 1;
      margin-right: 12px;
    }
  }
  .right {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}
