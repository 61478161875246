@import "../../import.scss";

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000077;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .modalInner {
    width: 100%;
    max-width: 80%;
    background-color: #eee;
    position: relative;
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0px 0px 4px 0px #00000099;
    max-height: 80%;
    overflow-y: auto;
    .closeModalButton {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
