@import "../../import.scss";

.StyledInput {
  display: flex;
  flex-direction: column;
  .styledInput {
    height: 25px;
    outline: none;
    border: none;
    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 100%;
    border-radius: 6px;
    box-shadow: $lightShadow;
    border: 1px solid $white;
    &:focus {
      border: 1px solid $orange;
    }
  }
}
