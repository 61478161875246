@import "../../import.scss";

.inner {
  flex: 1;
  overflow-y: auto;
  background-color: #f2f2f2;
  max-width: 1154px;
  margin: 0 auto;
  width: 100%;
  min-height: 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.layoutTitle {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: bold;
  font-size: 120%;
  .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #aaa;
    color: $white;
    cursor: pointer;

    height: 30px;
    width: 30px;
    border-radius: 30px;
    s &:hover {
      background-color: #888;
    }
  }
}
.header {
  display: flex;
  max-width: 1154px;
  width: 100%;
  margin: 0 auto;
  background-color: $white;
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid #00000022;
  .logo {
    font-size: 120%;
    font-variant: small-caps;
  }
  .nav {
    display: flex;
    gap: 24px;
    .navItem {
      cursor: pointer;
      &:hover {
        color: #444;
      }
    }
  }
}
