@import "../../import.scss";

.KeyValueForm {
  border-radius: 6px;
  .kvrow {
    display: flex;
    gap: 8px;
    margin-bottom: 4px;

    .key {
      flex: 1;
    }
    .value {
      flex: 1;
    }
    .removeRowButton {
      font-weight: bold;
      width: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ddd;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
  .addKeyValueButton {
    height: 40px;
    text-align: center;
    background-color: #ddd;

    box-shadow: $lightShadow;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
