@import "../../import.scss";

.PointSearchList {
  display: flex;
  flex-direction: column;
  .searchField {
  }
  .scrollArea {
    gap: 8px;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 8px;
  }
  .pointRow {
    display: flex;
    align-items: center;
    font-weight: bold;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    min-height: 50px;
    cursor: pointer;
    &:hover {
      background-color: #ddd;
    }
  }
  .pointImage {
    height: 50px;
    width: 50px;
    object-fit: cover;
    margin-right: 8px;
  }
}
