@import "../../import.scss";

.DNDList {
  overflow-y: auto;
  .dndInnerList {
    max-height: 250px;
  }
  .dndThumb {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 8px;
    background-color: $white;
  }
  .dndItem {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .num {
    width: 20px;
    text-align: center;
  }
  .dndItemName {
    flex: 1;
    padding-top: 4px;
    padding-bottom: 8px;
    margin-right: 8px;
    margin-left: 8px;
  }
  .dndIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
