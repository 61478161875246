@import "../../import.scss";

.Nav {
  background-color: #00000099;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80%;
  touch-action: none;
  overflow: hidden;
  z-index: 3;
  .navHeader {
    background-color: #27ae60;
    border-bottom: 2px solid #219251;
    height: 60px;
    color: $white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-left: 20px;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    .sub {
      font-weight: 400;
      font-size: 80%;
    }
    .closeNav {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      min-width: 40px;
      background-color: #27ae60;
      transition: background-color 0.2s;
      &:active {
        background-color: #219251;
      }
    }
  }
  .navItem {
    display: flex;
    height: 40px;
    padding-left: 24px;
    padding-right: 24px;
    color: $white;
    gap: 24px;
    text-decoration: none;
    transition: background-color 0.2s;
    &:active {
      background-color: #000000bb;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navLabel {
      display: flex;
      align-items: center;
    }
  }
}

.menuButton {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 45px;
  height: 45px;
  font-size: 150%;
  background-color: #27ae60;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  touch-action: none;
  border: 2px solid #219251;
  z-index: 2;
}
.menuSlide {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  touch-action: none;
  z-index: 1;
}
