@import "../../import.scss";

.Collection {
  .exhibitRow {
    background-color: white;
    margin-bottom: 9px;
    display: flex;
    font-weight: bold;
    border-radius: 6px;

    align-items: center;

    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    .deleteExhibitButton {
      background-color: red;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: $white;
      &:hover {
        color: $white;
        background-color: $red;
      }
    }
    &:hover {
      background-color: #ddd;
    }
    .exhibitName {
      flex: 1;
    }
    img {
      object-fit: cover;
      width: 60px;
      height: 60px;
      border: none;
      margin-right: 12px;
    }
  }
}
