@import "../../import.scss";

.ThreeDeeEditZone {
  

  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  border-radius: 6px;
  .threeDeeUpload {
    display: flex;
    height: 120px;
  }
  .edit3DZone {
    display: flex;
    gap: 24px;
  }
  .editArea {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
