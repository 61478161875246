html {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  overflow: hidden;
  min-width: 0;
}
body {
  min-width: 0;
  background-color: #ddd;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;

  min-width: 0;
}
.flex1 {
  flex: 1px;
}

.title {
  font-weight: bold;
  font-size: 120%;
  display: flex;
}
a {
  text-decoration: none;
  color: inherit;
}
