@import "../../import.scss";

.ExhibitForm {
  .row {
    display: flex;
    gap: 24px;
    .imageZone {
      min-height: 250px;
      width: 300px;
      display: flex;
      flex-direction: column;
      .removeImageButton,
      .starImageButton {
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        font-size: 80%;
        height: 30px;
        width: 30px;
        background-color: #00000099;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s, opacity 0.3s;
        &:hover {
          color: red;
          background-color: black;
        }
      }
      .currentMainImage {
        display: flex;
        flex: 1;
        position: relative;
        height: 250px;
        background-color: #777;
        &:hover {
          .removeImageButton {
            opacity: 1;
          }
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .otherImages {
        margin-top: 8px;
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        .smallImageWrap {
          height: 77px;
          width: 77px;
          background-color: #ddd;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          &:hover {
            .removeImageButton,
            .starImageButton {
              display: flex;
              opacity: 1;
            }
          }
          .removeImageButton {
            height: 25px;
            width: 25px;
            font-size: 60%;
            opacity: 0;
          }
          .starImageButton {
            opacity: 0;

            height: 25px;
            width: 25px;
            font-size: 60%;
            left: 0px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 4px;
            &:hover {
              color: gold;
            }
          }

          img {
            object-fit: contain;
          }
        }
        .smallUploader {
          width: 77px;
          height: 77px;
          display: flex;
          margin: 0;
          cursor: pointer;
          padding: 0;
        }
      }
    }
    .basicInfo {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 24px;
    }
  }
  .audioEditor {
    padding: 12px;
    padding-top: 0;
    border-radius: 6px;
    .audioUlWrap {
      height: 150px;
      display: flex;
    }

    .currentAudioFile {
      display: flex;
      audio {
        flex: 1;
      }
      .deleteAudioButton {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
          color: red;
        }
      }
    }
  }
}

.dotStyleEditor {
  padding: 8px;
  margin-top: 8px;
  border-radius: 6px;

  .sliderHolder {
    display: flex;
    align-items: center;
  }
  .sizeInput {
    all: unset;
    width: 40px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-size: 80%;
    font-weight: bold;
  }
  .pointTypes {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .pt {
      text-align: center;
      font-weight: bold;
      font-size: 80%;
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 8px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      transition: background-color 0.4s, color 0.4s;
      &.selected {
        background-color: #777;
        color: $white;
      }
      &:hover {
        .deleteThumbButton {
          opacity: 1;
        }
      }
    }
    .thumbHolder {
      display: flex;
      height: 50px;
      width: 50px;
    }
    .thumbWrapper {
      .deleteThumbButton {
        position: absolute;
        top: 0;
        right: 0;
        color: $white;
        background-color: red;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 4px;
        opacity: 0;
        transition: opacity 0.3s, color 0.3s;
        background-color: #00000099;
        &:hover {
          color: red;
        }
      }
    }
  }
  .dot {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: red;
  }
  .circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 10px solid red;
  }
  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.thumbPreview {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.miniUpload {
  width: 50px;
  height: 50px;
  display: flex;
}
