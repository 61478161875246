@import "../../import.scss";

.LanguageSwitch {
  margin-bottom: 12px;
  .top {
    display: flex;
    gap: 8px;
    .lang {
      width: 50px;
      font-size: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 4px;
      border-radius: 6px;
      cursor: pointer;
      background-color: white;
      &.selected {
        background-color: $orange;
        color:white;
        &:hover {
          background-color: $orange;
        }
      }
      &:hover {
        background-color: #ddd;
      }
      .flag {
        width: 20px;
      }
      .name {
        text-align: center;
      }
    }
  }
}
