@import "../../import.scss";

.StyledButton {
  height: 40px;
  min-height: 40px;
  text-align: center;
  background-color: $orange;

  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: bold;
  font-size: 110%;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: lighten($orange, 5%);
  }
  &.grey {
    background-color: #444;
    &:hover {
      background-color: lighten(#444, 5%);
    }
  }
  &.red {
    background-color: $red;
    &:hover {
      background-color: lighten($red, 5%);
    }
  }
}
