@import "../../import.scss";

.Home {
}

.test {
  touch-action: none;
  height: 100%;
}
.muesumsLink {
  background-color: $white;
  padding: 8px;
  margin-top: 8px;

  border-radius: 6px;
  display: flex;
  .mainLink {
    flex: 1;
  }
  .editLink {
    width: 30px;
    text-align: center;
    &:hover {
      color: green;
    }
  }
  &:hover {
    background-color: #ddd;
  }
}

.addMuseumButton {
  display: block;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  font-size: 90%;
  &:hover {
    color: #777;
  }
}

.homeBoxes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  .homeBox {
    width: 300px;
    height: 200px;
    min-width: 250px;
    width: 100%;
    max-width: 25%;
    display: flex;

    flex-direction: column;
    background-color: $white;
    border-radius: 6px;
    padding: 12px;
    .boxTop {
      display: flex;
      flex: 1;
      .boxIcon {
        background-color: #ddd;
        height: 50px;
        width: 50px;
        font-size: 200%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
      }
      .boxText {
        text-align: right;
        flex: 1;
        white-space: pre-wrap;
        .name {
          font-size: 140%;
        }
      }
    }
  }
}
