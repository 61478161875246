@import "../../import.scss";

.EditTour {
  position: relative;
  display: flex;
  .sidebar {
    position: absolute;
    z-index: 2;
    background-color: $white;
    overflow-y: auto;
    max-height: 600px;
    display: none;
  }
}
.canvasWrapper {
  position: relative;
  display: flex;
}
.editTourValuesButton {
  position: absolute;
  z-index: 2;
  top: 4px;
  left: 4px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: $lightShadow;
  &:hover {
    background-color: #eee;
  }
}

.editTourPointsButton {
  box-shadow: $lightShadow;
  position: absolute;
  z-index: 2;
  top: 4px;
  right: 4px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
}

.editTourPointsOverlay {
  padding-top: 0;
  width: 400px;
  border-radius: 6px;
  background-color: #eee;
  overflow-y: auto;
}
