@import "../../import.scss";

.unlockCodes {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  padding-bottom: 12px;
  .title {
    display: flex;
    justify-content: center;
    background-color: $orange;
    color: white;
    padding: 12px;
    margin-bottom: 24px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
    .backButton {
      position: absolute;
      left: 0;
      top: 0;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .qrHolder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .code {
    font-weight: bold;
    font-size: 400%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .uses {
    font-size: 200%;
    margin-top: 12px;
  }
  .quickOptions {
    display: flex;
    gap: 6px;
    .quickOption {
      flex: 1;
      text-align: center;
      height: 50px;
      background-color: #999;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: white;
      border-radius: 6px;
    }
  }
  .usesInput {
    font-size: 140%;
    height: 40px;
    text-align: center;
    margin-top: 24px;
    border-radius: 6px;
    outline: none;
    border: none;
    border: 1px solid #444;
    width: 100%;
    flex: 1;
    min-width: 0px;
  }
  .addRoom {
    margin: 12px;
    margin-bottom: 24px;
    margin-top: 0;
  }
}
